exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-all-needle-sequence-jsx": () => import("./../../../src/pages/all-needle-sequence.jsx" /* webpackChunkName: "component---src-pages-all-needle-sequence-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-cording-jsx": () => import("./../../../src/pages/cording.jsx" /* webpackChunkName: "component---src-pages-cording-jsx" */),
  "component---src-pages-double-beads-jsx": () => import("./../../../src/pages/double-beads.jsx" /* webpackChunkName: "component---src-pages-double-beads-jsx" */),
  "component---src-pages-dual-sequence-jsx": () => import("./../../../src/pages/dual-sequence.jsx" /* webpackChunkName: "component---src-pages-dual-sequence-jsx" */),
  "component---src-pages-exhibition-jsx": () => import("./../../../src/pages/exhibition.jsx" /* webpackChunkName: "component---src-pages-exhibition-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-lasser-jsx": () => import("./../../../src/pages/lasser.jsx" /* webpackChunkName: "component---src-pages-lasser-jsx" */),
  "component---src-pages-our-product-jsx": () => import("./../../../src/pages/our-product.jsx" /* webpackChunkName: "component---src-pages-our-product-jsx" */),
  "component---src-pages-single-sequence-jsx": () => import("./../../../src/pages/single-sequence.jsx" /* webpackChunkName: "component---src-pages-single-sequence-jsx" */)
}

